import React from "react";
import {
  Title,
  Wrapper,
  Container,
  Group,
  GroupTitle,
  GroupTable,
} from '../specifications/specifications.styled';
import { Icon } from "../../ui-components";


const Specifications = ({ content }) => {
  // const groups = content || {};

  const groups = [{
    title: 'General',
    data: content?.general || [],
  }, {
    title: 'Boat',
    data: content?.boat || [],
  }, {
    title: 'Camper',
    data: content?.camper || [],
  }, {
    title: 'Trike',
    data: content?.trike || [],
  }, {
    title: 'Trailer',
    data: content?.trailer || [],
  }]

  return (
    <Wrapper>
      <Container>
        <Title>Specifications</Title>
        {groups.map((group, index) => (
          <div key={index}>
            {
              group.data && group.data.length > 0 && (
                <Group>
                  <GroupTitle>{group.title}</GroupTitle>
                  {group.data.map((data, index) =>
                  (
                    <GroupTable container key={index}>
                      <GroupTable sep />
                      <GroupTable text>{data.name}</GroupTable>
                      <GroupTable spec>{data.value}</GroupTable>
                      <GroupTable sep />
                    </GroupTable>
                  ))}
                </Group>
              )
            }
          </div>
        ))}
      </Container>
    </Wrapper>
  )
}

export default Specifications;
