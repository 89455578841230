import styled, { css } from "styled-components";


export const Wrapper = styled.div`
  text-align: center;
  padding: 16px 0;
  background: ${({ theme }) => theme.background.paper};

  @media ${({ theme }) => theme.device.mobile}{
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const Container = styled.div`
  position: relative;
  max-width: ${({ theme }) => theme.size.container}px;
  margin: 0 auto;
  text-align: left;
  display: block;

  ${props => props.center && css`
    text-align: center;
  `}

  ${props => props.margin && css`
    margin: 32px auto;
  `}

  @media ${({ theme }) => theme.device.phone}{
    text-align: center;
  }
`;


export const Title = styled.h2`
  position: relative;
  text-align: center;
  font-family: "Quantico", sans-serif;
  font-size: 24px;
  display: block;
  margin: 16px auto 24px;
  color: ${({ theme }) => theme.text.primary};


  &::after{
    position: absolute;
    content: " ";
    display: block;
    background: linear-gradient(90deg, ${({ theme }) => theme.background.default} 0%, ${({ theme }) => theme.primary.main} 50%, ${({ theme }) => theme.background.default} 100%);
    height: 1px;
    left: 20%;
    right: 20%;
    bottom: -5px;
  }
`;


export const Group = styled.div`
  margin-bottom: 24px;

`;

export const GroupTitle = styled.h3`
  position: relative;
  text-align: center;
  font-family: "Quantico", sans-serif;
  font-size: 18px;
  padding: 16px 0;
  display: block;
  margin: auto;
  text-transform: uppercase;
  color: ${({ theme }) => theme.primary.main};
`;

export const GroupTable = styled.div`
  ${props => props.container && css`
    display: flex;
    flex-direction: row;
    padding: 8px;
    align-items: center;
    font-size: 16px;

    &:nth-child(2n){
      background: ${({ theme }) => theme.background.default};
    }
  `}

  ${props => props.sep && css`
      flex-basis: 48px;

      @media ${({ theme }) => theme.device.mobile}{
        display: none;
      }
  `}

  ${props => props.icon && css`
    padding-right: 8px;
    svg{
      fill: ${({ theme }) => theme.text.primary};
    }
  
  `}
  ${props => props.text && css`
    flex: 1;
    text-align: left;
    text-transform: uppercase;
    color: ${({ theme }) => theme.text.primary};
  
  `}
  ${props => props.spec && css`
    flex-basis: 200px;
    text-align: right;
    color: ${({ theme }) => theme.text.primary};  
  `}
`;
