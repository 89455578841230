import styled, { css } from "styled-components";


export const Wrapper = styled.div`
  text-align: center;
  padding: 8px 0px 42px;
  background: ${({ theme }) => theme.background.paper};

  @media ${({ theme }) => theme.device.mobile}{
    padding-left: 10px;
    padding-right: 10px;
  }
`;


export const Container = styled.div`
  position: relative;
  max-width: ${({ theme }) => theme.size.container}px;
  margin: 0 auto;
  text-align: left;
  display: block;

  ${props => props.center && css`
    text-align: center;
  `}

  ${props => props.margin && css`
    margin: 32px auto;
  `}

  @media ${({ theme }) => theme.device.phone}{
    text-align: center;
  }
`;

export const FeatureTitle = styled.h2`
  text-align: center;
  color: ${({ theme }) => theme.text.primary};
  font-family: "Quantico", sans-serif;
  font-size: 24px;
  font-weight: normal;
  margin: 24px 0;
`

export const BulletList = styled.ul`
  display: flex;
  flex-direction: row;
  margin: 8px auto 24px;
  list-style: none;
  align-items: center;
  justify-content: center;

  ${props => props.normal && css`
    display: inline-block;
    text-align: center;

    @media ${({ theme }) => theme.device.phone}{
      text-align: left;
    }

    ${Bullet}{
      display: inline-block;
      margin-top: 5px;
      margin-bottom: 5px;

      @media ${({ theme }) => theme.device.phone}{
        display: block;
        border: 1px solid transparent;
        margin-top: 10px;
        margin-bottom: 10px;
      }

      font-size: 16px;
      font-style: normal;
      color: ${({ theme }) => theme.text.primary};
    }
  `}

  @media ${({ theme }) => theme.device.tablet}{
    flex-direction: column;
  }

  @media ${({ theme }) => theme.device.mobile}{
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    
    /* display: inline-block; */

    padding-left: 24px;
    padding-right: 10px;
  }
`;

export const Bullet = styled.li`
  display: table-cell;
  position: relative;
  padding-left: 14px;
  padding-right: 14px;
  font-size: 17px;
  font-style: italic;
  margin: 0;
  color: ${({ theme }) => theme.text.secondary};

  @media ${({ theme }) => theme.device.tablet}{
    display: block;
    text-align: center;
    margin-top: 2px;
    margin-bottom: 2px;
  }

  @media ${({ theme }) => theme.device.tabletPortrait}{
    display: block;
    text-align: left;
    margin-top: 2px;
    margin-bottom: 2px;
  }

  @media ${({ theme }) => theme.device.phone}{
    display: block;
    text-align: left;
    margin-top: 2px;
    margin-bottom: 6px;
  }
  
  &::after{
    position: absolute;
    top: 5px;
    left: 0;
    content: " ";
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: ${({ theme }) => theme.primary.main};
  }
`;
